@use 'sass:color';
@import 'src/styles/mixins';

.base {
    text-align: left;

    a {
        @include card;

        animation: fade-in ease-in 1;
        animation-fill-mode: forwards;
        animation-duration: 0.5s;
        position: relative;
        color: $heading-color;
        display: flex;
        margin: 1rem auto;
        padding: 1rem;
        flex-flow: row wrap;
        gap: 0.5rem 1rem;

        &:hover {
            color: $heading-color;
        }

        @media (prefers-color-scheme: dark) {
            color: $dark-heading-color;

            &:hover {
                border-color: $dark-cta;
                color: $dark-heading-color;
            }
        }

        p,
        h3 {
            margin: 0;
        }
    }
}

@keyframes fade-in {
    from {
        top: -40px;
        opacity: 0;
    }

    to {
        top: 0;
        opacity: 1;
    }
}

.subject,
.person {
    margin: 0;
    font-size: $font-size-md;
    font-weight: 650;
    font-stretch: 110%;
    letter-spacing: -0.01em;

    @media (prefers-color-scheme: dark) {
        color: $dark-heading-color;
    }

    span {
        color: color.adjust($text-color, $lightness: 10%);

        @media (prefers-color-scheme: dark) {
            color: color.adjust($dark-text, $lightness: -10%);
        }
    }
}

.detail {
    flex-grow: 1;
    flex-basis: $breakpoint-mobile;
    display: flex;
    flex-flow: column;
    gap: 4px;

    section {
        display: flex;

        & > span {
            flex: 1 auto;
        }
    }
}

.body {
    @include line-clamp(2);
}

.body,
.datetime,
.from {
    font-weight: 350;
}

.body,
.datetime {
    color: $text-color-lightest;

    @media (prefers-color-scheme: dark) {
        color: $dark-text-lightest;
    }
}

.from {
    color: $heading-color;

    @media (prefers-color-scheme: dark) {
        color: $dark-heading-color;
    }
}

.datetime {
    text-align: right;
}

.startedOn,
.signCountDetail {
    @include small-caps;
}

.signatureCounts {
    font-size: $font-size-md;
    line-height: $line-height-md;
    display: flex;
    gap: 16px;

    .signCount {
        font-weight: 700;
    }
}
